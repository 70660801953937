import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  useTheme
} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { Announcement, ContactSupport } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type add = 'contract' | undefined;

export default function SupportPopover() {
  const [_add, setAdd] = useState<add>();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDialog = (add: add) => {
    setDialogOpen(true);
    setAdd(add);
    setAnchorEl(null);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setAdd(undefined);
  };

  const openJivo = () => {
    window.jivo_api.open();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{
          marginLeft: '24px',
          marginRight: '24px',
          minWidth: '30px',
          width: '35px'
        }}
        onClick={handleClick}
      >
        <Announcement fontSize="small" />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List sx={{ width: 320 }}>
          <ListItem onClick={openJivo} button>
            <ListItemIcon>
              <ContactSupport />
            </ListItemIcon>
            <ListItemText>{t('ASK_FOR_SUPPORT')}</ListItemText>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
