import {
  Avatar,
  Box,
  Button,
  Divider,
  Link,
  MenuItem,
  Typography
} from '@material-ui/core';
// material
import { alpha } from '@material-ui/core/styles';
import {
  AccountCircleOutlined,
  DashboardOutlined,
  SettingsOutlined
} from '@material-ui/icons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import { logOut } from '../../redux/slices/user';
import { RootState } from '../../redux/store';

// ----------------------------------------------------------------------
const AUTH_APP_LINK: string = process.env.REACT_APP_AUTH_APP_LINK as string;

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar
          alt="My Avatar"
          src={`https://ui-avatars.com/api/?name=${user.profile!.name}+${
            user.profile!.lastName
          }`}
        />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        {user.set && (
          <Link
            onClick={() => {
              window.location.href = `${AUTH_APP_LINK}/profile?accessToken=${
                localStorage.getItem('accessToken') as string
              }`;
            }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle1" noWrap>
                {user.profile?.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary' }}
                noWrap
              >
                {user.profile?.email}
              </Typography>
            </Box>
          </Link>
        )}
        <Divider sx={{ my: 1 }} />
        <MenuItem
          key="DASHBOARD"
          href="/dashboard/panel"
          component="a"
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',

              width: '100%'
            }}
          >
            {' '}
            <span style={{ marginRight: '1em' }}>
              <DashboardOutlined />
            </span>
            {t('DASHBOARD')}
          </Box>
        </MenuItem>
        <MenuItem
          key="PROFILE"
          component="a"
          onClick={() => {
            window.location.href = `${AUTH_APP_LINK}/profile?accessToken=${
              localStorage.getItem('accessToken') as string
            }`;
          }}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',

              width: '100%'
            }}
          >
            {' '}
            <span style={{ marginRight: '1em' }}>
              <AccountCircleOutlined />
            </span>
            {t('PROFILE')}
          </Box>
        </MenuItem>
        <MenuItem
          key="ADMIN_PANEL"
          href="/dashboard/settings"
          component="a"
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',

              width: '100%'
            }}
          >
            {' '}
            <span style={{ marginRight: '1em' }}>
              <SettingsOutlined />
            </span>
            {t('ADMIN_PANEL')}
          </Box>
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            onClick={() => {
              dispatch(logOut());
            }}
            fullWidth
            color="inherit"
            variant="outlined"
          >
            Çıkış Yap
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
