import backend from '../../../client/http';

export const isValidEmail = async (
  email: string,
  callback: (err: any) => void
) => {
  try {
    await backend.unauthorized.post(`/users/isValidEmail`, {
      email
    });
    callback(null);
  } catch (error) {
    callback(error);
  }
};
export const exists = async (email: string, callback: (err: any) => void) => {
  try {
    await backend.unauthorized.post(`/users/exists`, {
      email
    });
    callback(null);
  } catch (error) {
    callback(error);
  }
};

export const otpRequest = async (
  email: string,
  callback: (err: any, otpId: string | null) => void
) => {
  try {
    const { data } = await backend.unauthorized.post(`/otp/email/verify`, {
      email
    });
    callback(null, data.data._id);
  } catch (error) {
    callback(error, null);
  }
};

export const passwordResetOtpRequest = async (
  email: string,
  callback: (err: any, otpId: string | null) => void
) => {
  try {
    const { data } = await backend.unauthorized.post(`/otp/password/reset`, {
      email
    });
    callback(null, data.data._id);
  } catch (error) {
    callback(error, null);
  }
};

export const verifyOtp = async (
  { code, otpId }: { code: string; otpId: string },
  callback: (err: any, data: any | null) => void
) => {
  try {
    const { data } = await backend.unauthorized.get(
      `/otp/${otpId}/code/${code}`
    );
    callback(null, data.data);
  } catch (error) {
    callback(error, null);
  }
};

export const logIn = async (
  { email, password }: { email: string; password: string },
  callback: (err: any, data: any | null) => void
) => {
  try {
    const { data } = await backend.unauthorized.post(`/users/login`, {
      email,
      password
    });
    callback(null, data);
  } catch (error) {
    callback(error, null);
  }
};
export interface ISignupParams {
  email: string;
  password: string;
  name: string;
  lastName: string;
  company: {
    email: string;
    phone: string;
    name: string;
  };
}

export interface IResetPasswordParams {
  password: string;
  token: string;
}
export const signUp = async (
  params: ISignupParams,
  callback: (err: any, data: any | null) => void
) => {
  try {
    const { data } = await backend.unauthorized.post(`/users/register`, params);
    callback(null, data);
  } catch (error) {
    callback(error, null);
  }
};

export const resetPassword = async (
  params: IResetPasswordParams,
  callback: (err: any, data: any | null) => void
) => {
  try {
    backend.unauthorized.defaults.headers.Authorization = `Bearer ${params.token}`;
    const { data } = await backend.unauthorized.put(
      `/users/reset/password`,
      params
    );
    callback(null, data);
  } catch (error) {
    callback(error, null);
  }
};
