import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

export type IItem = {
  id: string;
  type: string;
  props?: any;
  _id?: string;
};

export type FormState = {
  _id?: string;
  name: string;
  fields: IItem[];
};
const initialState: FormState = {
  _id: '',
  name: '',
  fields: []
};

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setForm(state, action) {
      state = action.payload;
      return state;
    },
    setFields(state, action) {
      state.fields = action.payload;
      return state;
    },
    setName(state, action) {
      state.name = action.payload;
      return state;
    },
    setLabel(state, action) {
      const { index, value } = action.payload;
      state.fields[index].props.label = value;
      return state;
    },
    setRequired(state, action) {
      const { index, checked } = action.payload;
      state.fields[index].props.required = checked;
      return state;
    },
    removeField(state, action) {
      state.fields.splice(action.payload, 1);
      return state;
    },
    duplicateField(state, action) {
      const { index, item } = action.payload;
      state.fields.splice(index, 0, { ...item, id: uuid() });
      return state;
    },
    setOptionsLabel(state, action) {
      const { index, labels } = action.payload;
      state.fields[index].props.options = labels;
      return state;
    },
    setRowsOptions(state, action) {
      const { index, rowsOptions } = action.payload;
      state.fields[index].props.table.rows = rowsOptions;
      return state;
    },
    setColsOptions(state, action) {
      const { index, colsOptions } = action.payload;
      state.fields[index].props.table.cols = colsOptions;
      return state;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setForm,
  setFields,
  setName,
  setLabel,
  setRequired,
  removeField,
  duplicateField,
  setOptionsLabel,
  setColsOptions,
  setRowsOptions
} = slice.actions;
