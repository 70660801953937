// routes
// components

import {
  AppsOutlined,
  CalendarTodayTwoTone,
  Dashboard,
  DescriptionOutlined,
  GroupAddTwoTone,
  GroupTwoTone,
  LockOpenTwoTone,
  PersonAddTwoTone,
  PostAddTwoTone,
  SettingsTwoTone
} from '@material-ui/icons';
import SvgIconStyle from '../../components/SvgIconStyle';
import * as features from '../../consts/features';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  page: getIcon('ic_page'),
  dashboard: getIcon('ic_dashboard')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'GENERAL',
    items: [
      {
        active: true,
        title: 'DASHBOARD',
        href: PATH_DASHBOARD.general.dashboard,
        icon: <Dashboard />
        // scope: features.CLIENT_DASHBOARD_ACCESS
      },
      {
        active: true,
        title: 'ADMIN_PANEL',
        href: PATH_DASHBOARD.settings.settings,
        icon: <SettingsTwoTone />
        // scope: features.ACCOUNT_MANAGEMENT,
      },
      {
        active: true,
        title: 'APPS',
        href: PATH_DASHBOARD.general.applications,
        icon: <AppsOutlined />
        // scope: features.CONTRACTS
      },
      {
        active: true,
        title: 'CALENDAR',
        href: PATH_DASHBOARD.calendar,
        icon: <CalendarTodayTwoTone />
        // scope: features.CLIENT_DASHBOARD_ACCESS
      }
      // {
      //   active: false,
      //   title: 'ANALYSIS',
      //   href: PATH_DASHBOARD.general.analysis,
      //   icon: <AssessmentOutlined />,
      //   scope: 'ANALYSIS'
      // },
      // {
      //   active: false,
      //   title: 'REPORTS',
      //   href: PATH_DASHBOARD.general.reports,
      //   icon: <InsertDriveFileOutlined />,
      //   scope: features.REPORTS
      // }
    ]
  },
  {
    subheader: 'CLM',
    items: [
      {
        active: true,
        title: 'CONTRACTS',
        href: PATH_DASHBOARD.clm.contracts,
        icon: <DescriptionOutlined />,
        scope: features.CONTRACTS
      },
      {
        active: true,
        title: 'CONTRACT_TYPES',
        href: PATH_DASHBOARD.clm.contractTypes,
        icon: <DescriptionOutlined />,
        scope: features.CONTRACT_TYPE_MANAGEMENT
      }
    ]
  }
  // {
  //   subheader: 'APPS',
  //   items: [
  //     {
  //       active: true,
  //       title: 'JETDOCS',
  //       href: 'https://docs.jetlexa.com',
  //       icon: <DescriptionOutlined />,
  //       scope: features.CLIENT_DASHBOARD_ACCESS
  //     }
  //   ]
  // }
  //     {
  //       active: false,
  //       title: 'REPORTS',
  //       href: 'Reports',
  //       icon: <DescriptionRounded />,
  //       scope: features.CONTRACTS
  //     },
  //     {
  //       active: false,
  //       title: 'ANALYSIS',
  //       href: 'Contract Analysis',
  //       icon: <AnalyticsOutlined />,
  //       scope: features.CONTRACTS
  //     }
  //   ]
  // },
  // {
  //   subheader: 'DIGITAL_DOCUMENT_MANAGEMENT',
  //   items: [
  //     {
  //       active: false,
  //       title: 'TEMPLATES',
  //       href: 'Şablonlar',
  //       icon: <FolderOpen />,
  //       scope: features.CONTRACTS
  //     },
  //     {
  //       active: false,
  //       title: 'DOCUMNETS',
  //       href: 'Belgeler',
  //       icon: <NoteTwoTone />,
  //       scope: features.CONTRACTS
  //     }
  //   ]
  // }
];

// export const contractSidebarConfig = [
//   {
//     subheader: 'Sözleşme',
//     items: [
//       {
//         active: true,
//         title: 'Genel Bilgiler',
//         href: PATH_DASHBOARD.contracts.info,
//         icon: ICONS.page,
//         scope: features.CONTRACTS
//       },
//       {
//         active: true,
//         title: 'Taraflar',
//         href: PATH_DASHBOARD.contracts.parts,
//         icon: ICONS.page,
//         scope: features.CONTRACTS
//       },
//       {
//         active: true,
//         title: 'Hatırlatıcılar',
//         href: PATH_DASHBOARD.contracts.reminders,
//         icon: ICONS.page,
//         scope: features.CONTRACTS
//       },
//       {
//         active: true,
//         title: 'Önemli Hükümler',
//         href: PATH_DASHBOARD.contracts.terms,
//         icon: ICONS.page,
//         scope: features.CONTRACTS
//       },
//       {
//         active: true,
//         title: 'Dosyalar',
//         href: PATH_DASHBOARD.contracts.attachments,
//         icon: ICONS.page,
//         scope: features.CONTRACTS
//       },
//       {
//         active: true,
//         title: 'Form',
//         href: PATH_DASHBOARD.contracts.customform,
//         icon: ICONS.page,
//         scope: features.CONTRACTS
//       }
//     ]
//   }
// ];

export const settingsLinks = [
  {
    active: true,
    title: 'CONTRACT_TYPES',
    href: PATH_DASHBOARD.settings.contractTypes,
    scope: features.CONTRACT_TYPE_MANAGEMENT,
    icon: <PostAddTwoTone sx={{ fontSize: 32, marginRight: '5%' }} />
  },
  {
    active: true,
    title: 'ROLES',
    href: PATH_DASHBOARD.settings.roles,
    scope: features.ROLES_MANAGEMENT,
    icon: <LockOpenTwoTone sx={{ fontSize: 32, marginRight: '5%' }} />
  },
  {
    active: true,
    title: 'EMPLOYEES',
    href: PATH_DASHBOARD.settings.employees,
    scope: features.USER_MANAGEMENT,
    icon: <PersonAddTwoTone sx={{ fontSize: 32, marginRight: '5%' }} />
  },
  {
    active: true,
    title: 'DEPARTMENTS',
    href: PATH_DASHBOARD.settings.departments,
    scope: features.USER_MANAGEMENT,
    icon: <GroupTwoTone sx={{ fontSize: 32, marginRight: '5%' }} />
  },
  {
    active: true,
    title: 'PARTS',
    href: PATH_DASHBOARD.settings.vendors,
    scope: features.VENDOR_MANAGEMENT,
    icon: <GroupAddTwoTone sx={{ fontSize: 32, marginRight: '5%' }} />
  }
];

export default sidebarConfig;
