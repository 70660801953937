import { Box } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { ReactChild } from 'react';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    width: '50vw'
  },
  image: {
    backgroundImage: 'url(assets/clm-bg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export type TAuthLayoutProps = {
  children: ReactChild;
  leftChild?: ReactChild;
  title?: string;
};
export default function AuthLayout({
  children,
  leftChild,
  title
}: TAuthLayoutProps) {
  const classes = useStyles();
  const { loading } = useAuth();
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Grid container component="main" className={classes.root}>
        <CssBaseline />

        <Grid item xs={12} component={Paper} square>
          <div className={classes.paper}>
            <Box>
              <img
                src="/assets/logo_yatay.png"
                alt="logo"
                style={{ height: 75 }}
              />
            </Box>
            {/* <Box display="flex" flexDirection="column">
              <Typography
                style={{ marginTop: 50, marginBottom: 50 }}
                variant="h3"
              >
                {title}
              </Typography>
            </Box> */}
            {!loading && children}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
