import { useTranslation } from 'react-i18next';
import { Alert, alpha, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export default function GlobalTierAlert() {
  const { t } = useTranslation();

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const user = useSelector((state: RootState) => state.user);

  return user.profile?.tier?.isEnded ? (
    <Alert
      severity="info"
      variant="filled"
      style={{ borderRadius: 0 }}
      action={
        <Button
          color="inherit"
          size="small"
          variant="outlined"
          sx={{
            border: (theme) =>
              `1px solid ${alpha(theme.palette.common.white, 0.48)}`
          }}
          onClick={() => openInNewTab(t('TIER_EXPIRE_URL'))}
        >
          {t('SEE_THE_PLANS')}
        </Button>
      }
    >
      {t('TIER_EXPIRE')}
    </Alert>
  ) : (
    <></>
  );
}
