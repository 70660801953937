// END USER FEATURES
export const USER_MANAGEMENT = 'USER_MANAGEMENT';
export const CONTRACTS = 'CONTRACTS';
export const FILE_STORAGE = 'FILE_STORAGE';
export const ADVANCED_SEARCH = 'ADVANCED_SEARCH';
export const REMINDERS = 'REMINDERS';
export const REPORTS = 'REPORTS';
export const CLIENT_DASHBOARD_ACCESS = 'CLIENT_DASHBOARD_ACCESS';

// ADMIN-END USER MANAGEMENT FEATURES
export const ACCOUNT_MANAGEMENT = 'ACCOUNT_MANAGEMENT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO';
export const DEPARTMENTS_MANAGEMENT = 'DEPARTMENTS_MANAGEMENT';
export const BILLING_MANAGEMENT = 'BILLING_MANAGEMENT';
export const CONTRACT_TYPE_MANAGEMENT = 'CONTRACT_TYPE_MANAGEMENT';
export const VENDOR_MANAGEMENT = 'VENDOR_MANAGEMENT';
// BOTH
export const ROLES_MANAGEMENT = 'ROLES_MANAGEMENT';

export const SUPPORT = 'SUPPORT';
export const ADVANCED_SUPPORT = 'ADVANCED_SUPPORT';

export const DEMO = 'DEMO';
