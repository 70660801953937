import {
  Box,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography
} from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { matchPath, useLocation } from 'react-router-dom';

// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import { RootState } from '../../redux/rootReducer';
//
import SidebarConfig from './SidebarConfig';
import SidebarItem from './SidebarItem';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

type TNavItem = {
  icon?: ReactNode;
  info?: ReactNode;
  href: string;
  title: string;
  items?: TNavItem[];
  scope?: string;
  active?: boolean;
};

type ReduceChildParams = {
  array: ReactNode[];
  item: TNavItem;
  pathname: string;
  level: number;
};

function reduceChild({ array, item, pathname, level }: ReduceChildParams) {
  const key = item.href + level;
  const match = matchPath<{ id: string }>(pathname, {
    path: item.href,
    exact: false
  });
  const { href } = item;
  if (item.items) {
    return [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={href}
        title={item.title}
        scope={item.scope}
        open={Boolean(match)}
      >
        {/* <RenderSidebarItems
          pathname={pathname}
          level={level + 1}
          items={item.items}
        /> */}
        {/* {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items
        })} */}
      </SidebarItem>
    ];
  }
  return [
    ...array,
    <SidebarItem
      key={key}
      level={level}
      href={href}
      icon={item.icon}
      info={item.info}
      scope={item.scope}
      title={item.title}
      disabled={!item.active}
    />
  ];
}

type renderNavItemParams = {
  items: TNavItem[];
  pathname: string;
  level?: number;
};

function RenderSidebarItems({
  items,
  pathname,
  level = 0
}: renderNavItemParams) {
  return (
    <List disablePadding>
      {items.reduce<ReactNode[]>(
        (array, item) => reduceChild({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}

type NavBarProps = {
  isOpenSidebar?: boolean;
  onCloseSidebar?: VoidFunction;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar
}: NavBarProps) {
  const location = useLocation();

  const { pathname } = location;
  const [links] = useState(SidebarConfig);
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const AUTH_APP_LINK: string = process.env.REACT_APP_AUTH_APP_LINK as string;

  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 2.5, py: 3 }}>
        <Link href="/dashboard/panel">
          <Logo />
        </Link>
      </Box>

      {user.set && (
        <Link
          underline="none"
          component="a"
          href={`${AUTH_APP_LINK}/profile?accessToken=${
            localStorage.getItem('accessToken') as string
          }`}
        >
          <AccountStyle>
            <Avatar
              alt="My Avatar"
              src={`https://ui-avatars.com/api/?name=${user.profile!.name}+${
                user.profile!.lastName
              }`}
            />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                {user.profile?.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {user.profile?.tier?.demo
                  ? user.profile?.tier?.name
                  : user.profile?.role.name}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      )}

      {links.map((list) => (
        <List
          disablePadding
          key={list.subheader}
          subheader={
            <ListSubheader
              disableSticky
              disableGutters
              sx={{
                mt: 3,
                mb: 2,
                pl: 5,
                color: 'text.primary',
                typography: 'overline'
              }}
            >
              {t(list.subheader)}
            </ListSubheader>
          }
        >
          <RenderSidebarItems items={list.items} pathname={pathname} />
        </List>
      ))}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
