// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const HOME = '/';
const AUTH_APP_LINK: string = process.env.REACT_APP_AUTH_APP_LINK as string;

// ----------------------------------------------------------------------

export const PATH_HOME = {
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  components: '/components',
  dashboard: ROOTS_DASHBOARD
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: {
    demoEnded: path(HOME, '/demo-ended')
  },
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/panel'),
    contracts: path(ROOTS_DASHBOARD, '/contracts'),
    applications: path(ROOTS_DASHBOARD, '/applications'),
    reports: path(ROOTS_DASHBOARD, '/reports')
  },
  clm: {
    contracts: path(ROOTS_DASHBOARD, '/contracts'),
    contractTypes: path(ROOTS_DASHBOARD, '/settings/contracts')
  },
  settings: {
    settings: path(ROOTS_DASHBOARD, '/settings'),
    contractTypes: path(ROOTS_DASHBOARD, '/settings/contracts'),
    vendors: path(ROOTS_DASHBOARD, '/settings/vendors'),
    roles: path(ROOTS_DASHBOARD, '/settings/roles'),
    employees: `${AUTH_APP_LINK}/profile?accessToken=${
      localStorage.getItem('accessToken') as string
    }&tab=management`,
    departments: path(ROOTS_DASHBOARD, '/settings/departments'),
    profileInfo: `${AUTH_APP_LINK}/profile?accessToken=${
      localStorage.getItem('accessToken') as string
    }&tab=profile`,
    changePassword: `${AUTH_APP_LINK}/profile?accessToken=${
      localStorage.getItem('accessToken') as string
    }&tab=security`
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  contracts: {
    overview: path(ROOTS_DASHBOARD, '/contract-details/:id/overview'),
    editContract: path(ROOTS_DASHBOARD, '/contract-details/:id/edit')
  }
};
