import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Typography
} from '@material-ui/core';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  BusinessTwoTone,
  DescriptionOutlined,
  GroupTwoTone,
  PersonTwoTone,
  PostAddTwoTone
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import backend from '../../client/http';
// components
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 1)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const SearchResults = styled('div')(({ theme }) => ({
  top: APPBAR_MOBILE * 1.2,
  left: 0,
  zIndex: 98,
  width: '100%',
  position: 'absolute',
  height: '50vh',
  padding: theme.spacing(7),
  color: theme.palette.primary.main,
  borderTop: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)',
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 1)}`,
  overflowY: 'auto'
}));

// ----------------------------------------------------------------------

const RESULT_TRANSLATIONS: Record<any, any> = {
  CONTRACTS: 'Sözleşmeler',
  VENDORS: 'Taraflar',
  EMPLOYEES: 'Çalışanlar',
  DEPARTMENTS: 'Departmanlar',
  CONTRACT_TYPES: 'Sözleşme Tipleri'
};
const ICONS: Record<any, any> = {
  CONTRACTS: <DescriptionOutlined />,
  VENDORS: <BusinessTwoTone />,
  EMPLOYEES: <PersonTwoTone />,
  DEPARTMENTS: <GroupTwoTone />,
  CONTRACT_TYPES: <PostAddTwoTone />
};

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const [results, setResults] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [searchPopover, setSearchPopover] = useState(false);
  const [q, setQ] = useState('');
  const history = useHistory();
  const { t } = useTranslation();
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchPopover(false);
  };

  const handleSearch = useCallback(
    async (q: string) => {
      if (q && q.trim().length > 0) {
        try {
          const { data } = await backend.authorized.get(`/search?q=${q}`);
          setResults(data);
          if (data.length > 0) {
            setSearchPopover(true);
          } else {
            setSearchPopover(false);
            enqueueSnackbar(t('NOTHING_FOUND'), {
              variant: 'warning',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
            });
            setQ('');
          }
        } catch (error) {
          console.log(error);
          enqueueSnackbar(t('NOTHING_FOUND'), {
            variant: 'warning',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          });
        }
      }
    },
    [setResults, setSearchPopover, enqueueSnackbar]
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <MIconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </MIconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              onChange={(e) => setQ(e.target.value)}
              disableUnderline
              value={q}
              placeholder={t('SEARCH_PLACEHOLDER')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch(q);
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={() => handleSearch(q)}>
              {t('SEARCH')}
            </Button>
          </SearchbarStyle>
        </Slide>
        {searchPopover && (
          <SearchResults>
            <Grid container spacing={1}>
              {results.map((result: any) => (
                <Grid item xs={12} key={result.type}>
                  <List
                    subheader={
                      <Typography variant="h6">
                        {RESULT_TRANSLATIONS[result.type]}
                      </Typography>
                    }
                  >
                    {result.data.map((item: any) => {
                      const Icon = ICONS[result.type];
                      return (
                        <ListItem
                          button
                          key={item._id}
                          onClick={() => history.push(item.action)}
                        >
                          <ListItemIcon> {Icon}</ListItemIcon>
                          <ListItemText>{item.name}</ListItemText>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              ))}
            </Grid>
          </SearchResults>
        )}
      </div>
    </ClickAwayListener>
  );
}
