export const CREATE = 'CREATE';
export const READ = 'READ';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';

type ICREATE = typeof CREATE;
type IREAD = typeof READ;
type IUPDATE = typeof UPDATE;
type IDELETE = typeof DELETE;

type Operation = ICREATE | IREAD | IUPDATE | IDELETE;

export function HasAuthority(
  user: any,
  feature: string,
  operation?: Operation
) {
  const role: any = user.profile?.role?.scopes?.find(
    (scope: any) => scope.feature == feature
  );
  const authority = operation
    ? role?.operations?.includes(operation)
    : !!role?.operations?.length;

  return authority;
}
