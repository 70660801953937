import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import settingsReducer from './slices/settings';
import userReducer from './slices/user';
import formReducer from './slices/form';
import formValuesReducer from './slices/formValues';
import calendarReducer from './slices/calendar';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  user: userReducer,
  form: formReducer,
  calendar: calendarReducer,
  formValues: formValuesReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export { rootPersistConfig, rootReducer };
