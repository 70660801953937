import query from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import backend, { BACKEND } from '../client/http';
import { setUser } from '../redux/slices/user';
import { RootState } from '../redux/store';

export default function useAuth() {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const fetchCurrentUser = useCallback(
    async (providedToken?: string) => {
      setLoading(true);
      const q: any = query.parse(location.search);
      let token: String | null = null;
      if (q.accessToken) {
        token = q.accessToken;
        localStorage.setItem('accessToken', q.accessToken);
      } else if (localStorage.getItem('accessToken')) {
        token = localStorage.getItem('accessToken') as string;
      }
      let success: Boolean = false;
      if (token != null) {
        try {
          const { data } = await backend.authorized.get('/users/me', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          backend.authorized.defaults.headers.Authorization = `Bearer ${token}`;
          dispatch(setUser(data));
          success = true;
        } catch (error) {
          localStorage.removeItem('accesToken');
        } finally {
          setLoading(false);
          if (success) {
            if (!location.pathname.includes('dashboard')) {
              history.push('/dashboard');
            }
          } else {
            window.location.replace(`${process.env.REACT_APP_BACKEND}/auth`);
          }
        }
      } else {
        window.location.replace(`${process.env.REACT_APP_BACKEND}/auth`);
      }
    }, // 3bhv9f12@10mail.tk
    [dispatch, history, location]
  );
  const grabToken = useCallback(async () => {
    const token = localStorage.getItem('accessToken') as string;
    const q: any = query.parse(location.search);
    if (q.accessToken) {
      console.log('token is here');
      localStorage.setItem('accessToken', q.accessToken);
      // window.location.replace(
      //   location.pathname.split('/')[0] ? location.pathname : '/dashboard'
      // );
    }
    setTimeout(() => {
      if (!token) {
        const a = document.createElement('a');
        a.href = `${BACKEND}/auth`;
        a.click();
      }
    }, 1000);
  }, [location]);

  useEffect(() => {
    // grabToken();
    fetchCurrentUser();
  }, [fetchCurrentUser]); // grabToken,
  useEffect(() => {
    // redirectToAuth();
  }, []);
  return { loading, user, fetchCurrentUser };
}
