import React from 'react';
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';

export type TAuthGuardProps = {
  children: any;
};
export default function AuthGuard({ children }: TAuthGuardProps) {
  const { loading } = useAuth();
  return loading ? <LoadingScreen /> : children;
}
