import useStateMachine from '@cassiozen/usestatemachine';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import AuthLayout from '../../../layouts/auth';
import { exists, logIn } from '../func';
import useStyles from './styles';

const emailForm = {
  field: {
    type: 'email',
    helperText: 'Kayıt olurken kullandığınız e-posta adresinizi giriniz',
    label: 'E-posta adresiniz',
    placeholder: 'info@jetlexa.com',
    disabled: false,
    loading: false
  },
  action: 'İleri'
};
const passwordForm = {
  field: {
    type: 'password',
    helperText: '',
    label: 'Şifreniz',
    placeholder: '',
    disabled: false,
    loading: false
  },
  action: 'Giriş Yap'
};

const loading = {
  field: {
    type: 'password',
    helperText: '',
    label: 'Şifreniz',
    placeholder: '',
    disabled: true,
    loading: true
  },
  action: 'Giriş Yapılıyor'
};
type formData = Record<'email' | 'password', string>;
export default function Login() {
  const [errorState, setErrorState] = useState({
    message: '',
    hasError: false
  });
  const [formData, setFormData] = useState<formData>({
    email: '',
    password: ''
  });
  const [state, send] = useStateMachine()({
    initial: 'email',
    states: {
      email: {
        on: { NEXT: 'checkingEmail', EMAIL: 'email' }
      },
      checkingEmail: {
        on: { NEXT: 'password', BACK: 'email' },
        effect() {
          if (formData.email) {
            exists(formData.email, (err) => {
              if (err) {
                setErrorState({
                  hasError: true,
                  message: `${err.response.data.message}`
                });
                send('BACK');
              } else {
                send('NEXT');
                setErrorState({
                  hasError: false,
                  message: ``
                });
              }
            });
          }
        }
      },
      password: {
        on: { NEXT: 'logIn', BACK: 'email' }
      },
      logIn: {
        on: { BACK: 'password', NEXT: 'done' },
        effect() {
          if (formData.email) {
            logIn(formData, (err, data) => {
              if (err) {
                setErrorState({
                  hasError: true,
                  message: `${err.response.data.message}`
                });
                send('BACK');
              } else {
                localStorage.setItem('accessToken', data.accessToken);
                setTimeout(() => {
                  send('NEXT');
                }, 1000);
                setErrorState({
                  hasError: false,
                  message: ``
                });
              }
            });
          }
        }
      },
      done: {
        effect() {
          window.location.replace('/dashboard');
        }
      }
    }
  });
  const formProps = () => {
    switch (state.value) {
      default:
        return emailForm;
      case 'email':
        return emailForm;
      case 'password':
        return passwordForm;
      case 'logIn':
        return loading;
    }
  };
  const form = formProps();
  const classes = useStyles();
  const actionDisabled =
    Object.values(formData).filter((v) => v.trim()).length === 0;
  return (
    <AuthLayout title="Giriş Yap">
      <>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          paddingLeft="20%"
          paddingRight="20%"
        >
          {state.value !== 'done' ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button disabled fullWidth variant="outlined">
                  Google ile giriş yapın
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button disabled fullWidth variant="outlined">
                  Linkedin ile giriş yapın
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.title}>
                  <span> ya da e-posta ile </span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  key={form.field.type}
                  name={form.field.type}
                  fullWidth
                  type={form.field.type}
                  error={errorState.hasError}
                  placeholder={form.field.placeholder}
                  helperText={
                    errorState.hasError
                      ? errorState.message
                      : form.field.helperText
                  }
                  label={form.field.label}
                  disabled={form.field.disabled}
                  onChange={(e) => {
                    if (e.target.value) {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value
                      });
                    } else {
                      setErrorState({ hasError: false, message: '' });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={actionDisabled}
                  onClick={() => send('NEXT')}
                >
                  {form.action}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography>
                    Hesabınız yok mu? <Link to="/signup">Kayıt olun.</Link>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography>
                    <Link to="/forgot">Şifremi unuttum</Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography align="center" variant="h5">
                  Başarıyla giriş yaptınız
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  style={{ marginTop: 50, marginBottom: 50 }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </>
    </AuthLayout>
  );
}
