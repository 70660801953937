import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_DASHBOARD } from './paths';
// layouts
import DashboardLayout from '../layouts/dashboard';

// ----------------------------------------------------------------------

const DashboardRoutes = {
  path: PATH_DASHBOARD.root,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.general.dashboard,
      component: lazy(() => import('../views/dashboard'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.clm.contracts,
      component: lazy(() => import('../views/contracts'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.applications,
      component: lazy(() => import('../views/applications'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.reports,
      component: lazy(() => import('../views/reports'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.root,
      component: () => <Redirect to={PATH_DASHBOARD.general.dashboard} />
    },
    // APP
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.settings.contractTypes,
      component: lazy(() => import('../views/assets/contract-types-v2'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.settings.departments,
      component: lazy(() => import('../views/departments'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.settings.vendors,
      component: lazy(() => import('../views/assets/vendors'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.settings.employees,
      component: lazy(() => import('../views/company/user'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.settings.roles,
      component: lazy(() => import('../views/roles'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.settings.changePassword,
      component: lazy(() => import('../views/account/change_password'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.settings.profileInfo,
      component: lazy(() => import('../views/account/profile_info'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.dashboard,
      component: () => <Redirect to={PATH_DASHBOARD.general.dashboard} />
    },
    {
      exact: true,
      path: PATH_DASHBOARD.contracts.overview,
      component: lazy(() => import('../views/overview'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.contracts.editContract,
      component: lazy(() => import('../views/contracts/edit'))
    },

    {
      exact: true,
      path: PATH_DASHBOARD.settings.settings,
      component: lazy(() => import('../views/settings'))
    },

    // APP : CALENDAR
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.calendar,
      component: lazy(() => import('../views/calendar'))
    },

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default DashboardRoutes;
