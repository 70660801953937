import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    width: '100%',
    textAlign: 'center',
    borderBottom: '1px solid #000 ',
    lineHeight: '0.1em',
    margin: '10px 0 20px',
    '& span': {
      background: '#fff',
      padding: '0 10px'
    }
  }
}));
export default useStyles;
