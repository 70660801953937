import { createBrowserHistory } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import LoadingScreen from './components/LoadingScreen';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
// redux
import { persistor, store } from './redux/store';
// routes
import routes, { renderRoutes } from './routes';
// theme
import ThemeConfig from './theme';
//

// ----------------------------------------------------------------------

const history = createBrowserHistory();

export default function App() {
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <ThemeConfig>
            <RtlLayout>
              <Router history={history}>
                <ScrollToTop />
                <SnackbarProvider maxSnack={1}>
                  {renderRoutes(routes)}
                </SnackbarProvider>
              </Router>
            </RtlLayout>
          </ThemeConfig>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  );
}
