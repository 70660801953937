import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContractForm from './contract';

export type TContractModalProps = {
  open: boolean;
  onClose: () => void;
};

export default function ContractModal(props: TContractModalProps) {
  const [dialogOpen, setDialogOpen] = useState(props.open);
  useEffect(() => {
    setDialogOpen(props.open);
  }, [props.open]);
  const { t } = useTranslation();
  return (
    <>
      <Dialog fullWidth maxWidth="sm" onClose={props.onClose} open={dialogOpen}>
        <DialogTitle>{t('CREATE_CONTRACT')}</DialogTitle>
        <DialogContent>
          <ContractForm />
        </DialogContent>
      </Dialog>
    </>
  );
}
