import { createSlice } from '@reduxjs/toolkit';

import { IInputFieldValue } from '../../@types/app/form/form';

interface IState {
  data: IInputFieldValue[];
}

const initialState: IState = {
  data: []
};

const mapToValues = (form: IInputFieldValue[]) =>
  form.map((block) => {
    if (!block.props.value) {
      switch (block.type) {
        case 'multiple':
          return { ...block, props: { ...block.props, value: [] } };
        case 'date':
          return {
            ...block,
            props: { ...block.props, value: null }
          };
        case 'single':
          return {
            ...block,
            props: { ...block.props, value: null }
          };
        case 'multipleTable':
          return {
            ...block,
            props: {
              ...block.props,
              value: new Array(block.props.table.rows.length).fill(
                new Array(block.props.table.cols.length).fill(undefined)
              )
            }
          };
        case 'singleTable':
          return {
            ...block,
            props: { ...block.props, value: [] }
          };
        case 'time':
          return {
            ...block,
            props: { ...block.props, value: null }
          };
        default:
          return {
            ...block,
            props: { ...block.props, value: '' }
          };
      }
    } else {
      return block;
    }
  });

const slice = createSlice({
  name: 'formValues',
  initialState,
  reducers: {
    setFormValues(state, action) {
      state.data = mapToValues(action.payload);
      return state;
    },
    setFieldValue(state, action) {
      const { index, value } = action.payload;
      state.data[index].props.value = value;
      return state;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFormValues, setFieldValue } = slice.actions;
