import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Toolbar,
  Typography
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import Logo from '../../components/Logo';
import Page from '../../components/Page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    }
  })
);

export default function DemoEnded() {
  const classes = useStyles();

  return (
    <Page title="Demo süreniz sona erdi">
      <AppBar position="static" color="default">
        <Container>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <Logo fileName="logo_yatay.png" />
            </Typography>
            <Button
              onClick={() => {
                localStorage.clear();
                window.location.replace('/');
              }}
              color="inherit"
            >
              Çıkış Yap
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <Container>
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                alt="welcome"
                src="/static/illustrations/illustration_seo.svg"
                sx={{
                  p: 2,
                  height: 280,
                  margin: { xs: 'auto', md: 'inherit' }
                }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography mt={4} variant="h3">
                Deneme Süreniz Sona Erdi
              </Typography>
              <Typography mt={4}>
                <strong> Jetlexa CLM</strong>'i beğendiğinizi umuyoruz.
              </Typography>
              <Typography mt={2}>
                Paketlerimiz hakkında bilgi almak için aşağıdaki butonu
                tıklayabilir veya aboneliğinizi hemen başlatmak için randevu
                alabilirsiniz.
              </Typography>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={8}>
              <Box display="flex" alignItems="center">
                <Box>
                  <Button
                    component="a"
                    href="https://www.jetlexa.com/planlar"
                    variant="outlined"
                  >
                    Paketlere Göz At
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    component="a"
                    href="https://www.jetlexa.com/gorusme-planlayalim-dijital-sozlesme-platformu#Demo-link"
                    variant="contained"
                  >
                    Randevu Al
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
