import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Autocomplete
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useImmer } from 'use-immer';
import { useSnackbar } from 'notistack';
import backend from '../../../../client/http';
import { Form } from '../../../formbuilder';

export type TNewContract = {
  name: string;
  contractType: string;
};
interface IEmployee {
  _id: string;
  name: string;
  lastName: string;
  email: string;
}
export default function NewContractForm() {
  const [state, setState] = useImmer<TNewContract>({
    name: '',
    contractType: 'standart'
  });
  const [types, setTypes] = useState<Form[]>([]);
  const history = useHistory();
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<IEmployee[]>([]);
  const fetchEmployees = useCallback(async () => {
    try {
      const res = await backend.authorized.get('/company/employees');

      setEmployees(res.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [setEmployees]);
  const { enqueueSnackbar } = useSnackbar();
  const fetchContractTypes = useCallback(async () => {
    try {
      const res = await backend.authorized.get('/assets/contracts');
      setTypes(res.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [setTypes]);

  const createContract = async () => {
    try {
      console.log({
        ...state,
        authorizeds: selectedEmployees.map((emp) => emp._id)
      });
      await backend.authorized
        .post('/contracts', {
          ...state,
          authorizeds: selectedEmployees.map((emp) => emp._id)
        })
        .then((res) => {
          history.push(
            `/dashboard/contract-details/${res.data.data._id}/overview`
          );
        })
        .catch((err) => {
          enqueueSnackbar(err.response.data.message ?? 'Request failed.', {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
          });
        });
    } catch (error: any) {
      console.log(error);
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setState((draft) => {
      draft[e.target.name as keyof TNewContract] = e.target.value;
    });
  };

  useEffect(() => {
    fetchContractTypes();
  }, [setTypes, fetchContractTypes]);
  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);
  const { t } = useTranslation();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          onChange={onChange}
          name="name"
          variant="outlined"
          fullWidth
          value={state.name}
          label={`${t('CONTRACT')} ${t('NAME')}`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          type="type"
          select
          name="contractType"
          onChange={onChange}
          variant="outlined"
          value={state.contractType}
          fullWidth
          label={`${t('CONTRACT')} ${t('TYPE')}`}
        >
          <MenuItem value="standart">{t('STANDART_CONTRACT_TYPE')}</MenuItem>
          {types.map((type) => (
            <MenuItem value={type._id} key={type._id}>
              {type.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          fullWidth
          options={employees}
          value={selectedEmployees}
          onChange={(e, value) => setSelectedEmployees(value)}
          getOptionLabel={(option) => `${option.name} ${option.lastName}`}
          noOptionsText={t('NOTHING_FOUND')}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('MEMBERS')}
              placeholder={t('SELECT_MEMBERS')}
              // helperText="Yeni bir kişi eklemek için  + butonuna basın"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={createContract} variant="contained">
            {t('CREATE')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
