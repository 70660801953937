import { createSlice } from '@reduxjs/toolkit';

import { TUser } from '../../@types/user';

// ----------------------------------------------------------------------

type UserState = {
  profile?: TUser;
  set: boolean;
};

const initialState: UserState = {
  set: false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.profile = action.payload;
      state.set = true;
      return state;
    },
    logOut(state) {
      state.set = false;
      setTimeout(() => {
        localStorage.removeItem('accessToken');
        window.location.replace('/');
      }, 1000);
      return state;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setUser, logOut } = slice.actions;
