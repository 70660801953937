import { useRef, useState } from 'react';
// material
import { Box, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// components
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg'
  },
  {
    value: 'tr',
    label: 'Turkish',
    icon: '/static/icons/ic_flag_tr.png'
  }
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const defaultLang = LANGS.find(
    (l) => l.value === (localStorage.getItem('i18nextLng') as string)
  );
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultLang || LANGS[0]);
  const { i18n } = useTranslation();
  const handleLang = (lang: typeof LANGS[0]) => {
    setOpen(false);
    setValue(lang);
    i18n.changeLanguage(lang.value);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          padding: 0,
          width: 24,
          height: 24,
          ...(open && { bgcolor: 'action.selected' })
        }}
      >
        <img src={value.icon} alt={value.label} />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === LANGS[0].value}
              onClick={() => handleLang(option)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box
                  component="img"
                  alt={option.label}
                  src={option.icon}
                  style={{
                    width: 24
                  }}
                />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
