import useStateMachine from '@cassiozen/usestatemachine';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  GridSize,
  TextField,
  Typography
} from '@material-ui/core';
import { pick } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import AuthLayout from '../../../layouts/auth';
import {
  isValidEmail,
  passwordResetOtpRequest,
  resetPassword,
  verifyOtp
} from '../func';

const emailForm = {
  fields: [
    {
      type: 'email',
      key: 'email',
      helperText: 'Sisteme kayıt olduğunuz e-posta adresinizi giriniz',
      label: 'E-posta adresiniz',
      placeholder: 'info@jetlexa.com',
      disabled: false,
      loading: false,
      size: 12
    }
  ],
  action: 'İleri'
};
const resetPasswordForm = {
  fields: [
    {
      key: 'password',
      type: 'password',
      helperText: '',
      label: 'Şifreniz',
      placeholder: '',
      disabled: false,
      loading: false,
      size: 12
    }
  ],
  action: 'Şifremi sıfırla'
};

const otpForm = {
  fields: [
    {
      type: 'text',
      key: 'code',
      helperText: '',
      label: 'Doğrulama Kodu',
      placeholder: '',
      disabled: false,
      loading: false,
      size: 12
    }
  ],
  action: 'Doğrula'
};

const loading = {
  fields: [],
  action: 'Şifreniz sıfırlanıyor'
};

export default function Forgot() {
  const history = useHistory();
  const [errorState, setErrorState] = useState({
    message: '',
    hasError: false
  });

  const [token, setToken] = useState<string>('');

  const [formData, setFormData] = useState<Record<string, string>>({
    email: '',
    password: '',
    companyName: '',
    companyPhone: '',
    code: '',
    otpId: ''
  });
  const { t } = useTranslation();
  const [state, send] = useStateMachine()({
    initial: 'email',
    states: {
      email: {
        on: { NEXT: 'checkingEmail', BACK: 'email' }
      },
      checkingEmail: {
        on: { NEXT: 'otpForm', BACK: 'email' },
        effect() {
          if (formData.email) {
            isValidEmail(formData.email, (err) => {
              if (!err) {
                setErrorState({
                  hasError: true,
                  message: `Bu e-posta adresi bulunamadı`
                });
                send('BACK');
              } else {
                passwordResetOtpRequest(formData.email, (err, id) => {
                  if (!err) {
                    send('NEXT');
                    setErrorState({
                      hasError: false,
                      message: ``
                    });
                    setFormData({ ...formData, otpId: id as string });
                  } else {
                    send('BACK');
                    setErrorState({
                      hasError: false,
                      message: `Doğrulama kodu yollanamadı, mail edresinizi kontrol edin.`
                    });
                  }
                });
              }
            });
          }
        }
      },
      otpForm: {
        on: { NEXT: 'verifyingOtp', BACK: 'email' }
      },
      verifyingOtp: {
        on: { NEXT: 'resetPasswordForm', BACK: 'email' },
        effect() {
          if (formData.email) {
            verifyOtp(
              { otpId: formData.otpId, code: formData.code },
              (err, data) => {
                if (err) {
                  setErrorState({
                    hasError: true,
                    message: `Geçersiz doğrulama kodu`
                  });
                  send('BACK');
                } else {
                  send('NEXT');
                  setToken(data);
                  setErrorState({
                    hasError: false,
                    message: ``
                  });
                }
              }
            );
          }
        }
      },
      resetPasswordForm: {
        on: { NEXT: 'resettingPassword', BACK: 'email' }
      },
      resettingPassword: {
        on: { NEXT: 'done', BACK: 'resetPasswordForm' },
        effect() {
          if (formData.email) {
            resetPassword(
              {
                password: formData.password,
                token
              },
              (err, data) => {
                if (err) {
                  setErrorState({
                    hasError: true,
                    message: `${err.response.data.message}`
                  });
                  send('BACK');
                } else {
                  send('NEXT');
                  localStorage.setItem('accessToken', data.accessToken);
                  setTimeout(() => {
                    history.push('/dashboard');
                  }, 1000);
                }
              }
            );
          }
        }
      },
      done: {}
    }
  });
  const formProps = () => {
    switch (state.value) {
      default:
        return emailForm;
      case 'email':
        return emailForm;
      case 'resetPasswordForm':
        return resetPasswordForm;
      case 'otpForm':
        return otpForm;
      case 'resettingPassword':
        return loading;
    }
  };
  const form = formProps();

  const values = Object.values(
    pick(
      formData,
      form.fields.map((f) => f.key)
    )
  );

  const actionDisabled = values.filter((v) => v.trim()).length === 0;

  return (
    <AuthLayout>
      <form>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          paddingLeft="20%"
          paddingRight="20%"
        >
          {state.value !== 'done' ? (
            <Grid container spacing={3}>
              {form.fields.map((field) => (
                <Grid item xs={field.size as GridSize} key={field.label}>
                  <TextField
                    name={field.key}
                    fullWidth
                    defaultValue={formData[field.key as string]}
                    type={field.type}
                    error={errorState.hasError}
                    placeholder={field.placeholder}
                    helperText={
                      errorState.hasError
                        ? errorState.message
                        : field.helperText
                    }
                    label={field.label}
                    disabled={field.disabled}
                    onChange={(e) => {
                      if (e.target.value) {
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value
                        });
                      } else {
                        setErrorState({ hasError: false, message: '' });
                      }
                    }}
                  />
                </Grid>
              ))}

              {state.value !== 'email' && (
                <Grid item xs={6}>
                  <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disabled={actionDisabled}
                    onClick={() => send('BACK')}
                  >
                    {t('BACK')}
                  </Button>
                </Grid>
              )}
              <Grid item xs={state.value !== 'email' ? 6 : 12}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={actionDisabled}
                  onClick={() => send('NEXT')}
                >
                  {form.action}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography>
                    Şifrenizi biliyorsanız <Link to="/"> giriş yapın</Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography align="center" variant="h5">
                  Şifreniz sıfırlandı
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  style={{ marginTop: 50, marginBottom: 50 }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              </Grid>
              <Grid item xs={12}>
                {' '}
                <Typography align="center" variant="subtitle2">
                  Giriş ekranına yönlendiriliyorsunuz.
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </form>
    </AuthLayout>
  );
}
