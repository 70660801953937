import axios from 'axios';

export const BACKEND = process.env.REACT_APP_BACKEND;

const authorized = axios.create({
  baseURL: BACKEND,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken') as string}`
  }
});

const unauthorized = axios.create({
  baseURL: BACKEND
});

const backend = {
  authorized,
  unauthorized
};

export default backend;
