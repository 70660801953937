import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { handleAuthorizationAlert } from '../../redux/slices/settings';

export default function AuthorizationAlertDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openAuthorizationAlert = useSelector(
    (state: RootState) => state.settings.openAuthorizationAlert
  );

  const handleClose = useCallback(
    () => dispatch(handleAuthorizationAlert(false)),
    [dispatch, false]
  );
  return (
    <>
      <Dialog open={openAuthorizationAlert} onClose={handleClose}>
        <DialogTitle>{t('ROLE_AUTHORIZATION_ALERT_TITLE')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('ROLE_AUTHORIZATION_ALERT')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t('CLOSE')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
