import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Grid } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// theme
import { Location } from 'history';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import typography from '../../theme/typography';
import { HasAuthority } from '../../utils/authorizeService';
import * as features from '../../consts/features';
import { handleAuthorizationAlert } from '../../redux/slices/settings';
// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...typography.body2,
  height: 48,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&.isActiveRoot': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: 'absolute',
      backgroundColor: theme.palette.primary.main
    }
  },
  '&.isActiveSub': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    '& .subIcon:before': {
      transform: 'scale(2)',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const SubIconStyle = styled('span')(({ theme }) => ({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:before': {
    width: 4,
    height: 4,
    content: "''",
    display: 'block',
    borderRadius: '50%',
    backgroundColor: theme.palette.text.disabled,
    transition: theme.transitions.create('transform')
  }
}));

// ----------------------------------------------------------------------

type NavItemProps = {
  level: number;
  title: string;
  href?: string;
  scope?: string;
  info?: ReactNode;
  icon?: ReactNode;
  open?: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
};

export default function SidebarItem({
  level,
  title,
  href,
  info,
  icon,
  scope,
  open = false,
  children,
  className,
  disabled
}: NavItemProps) {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const [show, setShow] = useState(open);
  const isSubItem = level > 0;

  const handleShow = () => {
    setShow((show) => !show);
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          button
          disableGutters
          onClick={handleShow}
          disabled={disabled}
          className={open ? 'isActiveRoot' : ''}
        >
          <ListItemIcon>{icon && icon}</ListItemIcon>
          <ListItemText disableTypography primary={t(title)} />
          {info && info}
          <Box
            component={Icon}
            icon={show ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
          {disabled && (
            <ListItemSecondaryAction>Çok yakında</ListItemSecondaryAction>
          )}
        </ListItemStyle>

        <Collapse in={show}>{children}</Collapse>
      </>
    );
  }
  const hasAuthority: boolean = scope ? HasAuthority(user, scope) : true;

  if (!hasAuthority) {
    return (
      <>
        <Tooltip title={t('ROLE_AUTHORIZATION_ALERT') as string}>
          <Grid
            container
            direction="row"
            alignItems="center"
            sx={{
              height: 48,
              textTransform: 'capitalize',
              paddingLeft: '15%',
              paddingRight: '15%'
            }}
          >
            <Grid>
              {isSubItem ? <SubIconStyle className="subIcon" /> : icon}
            </Grid>
            <Grid sx={{ paddingLeft: '6%' }}>{t(title)}</Grid>
          </Grid>
        </Tooltip>
      </>
    );
  }

  return (
    <ListItemStyle
      button
      // @ts-ignore
      to={href}
      exact={open}
      disableGutters
      component={RouterLink}
      disabled={disabled}
      activeClassName={isSubItem ? 'isActiveSub' : 'isActiveRoot'}
      isActive={(match: { url: string }, location: Location) => {
        if (!match) {
          return false;
        }

        const { url } = match;
        const { pathname } = location;
        const isMatch = url === pathname;

        if (!isSubItem) {
          return url.length && pathname.includes(url);
        }

        return isMatch;
      }}
    >
      <ListItemIcon>
        {isSubItem ? <SubIconStyle className="subIcon" /> : icon}
      </ListItemIcon>
      <ListItemText primary={t(title)} />

      {info && info}
    </ListItemStyle>
  );
}
