import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

type LogoProps = {
  fileName?: string;
} & BoxProps;
export default function Logo(props: LogoProps) {
  return (
    <Box
      component="img"
      alt="logo"
      src={`/assets/${props.fileName || 'logo_kare.png'}`}
      height={40}
      {...props}
    />
  );
}
